import React from "react";
import PropTypes from "prop-types";

function ContentFrame({ children }) {
  return <div className="md:mt-12 mt-10 container mx-auto">{children}</div>;
}

ContentFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentFrame;
