import React from "react";

import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import * as styles from "./button.module.scss";

function Button({
  size,
  bgColor,
  isAnchor,
  textColor,
  children,
  shadow,
  to,
  className,
  href,
  target,
  style,
}) {
  return (
    <>
      {to ? (
        isAnchor ? (
          <AnchorLink
            to={to}
            className={`${
              styles.link
            } bg-${bgColor} text-${textColor} font-light ${
              shadow ? "shadow-lg" : ""
            }`}
            style={style}
          >
            <div className={`${className}`}>
              <span className={styles.span}>&#10230;</span>
              <span
                className="py-4"
                dangerouslySetInnerHTML={{ __html: children }}
              ></span>
            </div>
          </AnchorLink>
        ) : (
          <Link
            to={to}
            className={`${
              styles.link
            } bg-${bgColor} text-${textColor} font-light ${
              shadow ? "shadow-lg" : ""
            }`}
            style={style}
          >
            <div className={`${className}`}>
              <span className={styles.span}>&#10230;</span>
              <span
                className="py-4"
                dangerouslySetInnerHTML={{ __html: children }}
              ></span>
            </div>
          </Link>
        )
      ) : (
        <a
          href={href}
          className={`${
            styles.link
          } bg-${bgColor} text-${textColor} font-light ${
            shadow ? "shadow-lg" : ""
          }`}
          target={target}
          style={style}
        >
          <div className={`${className}`}>
            <span className={styles.span}>&#10230;</span>
            <span
              className="py-4"
              dangerouslySetInnerHTML={{ __html: children }}
            ></span>
          </div>
        </a>
      )}
    </>
  );
}

export default Button;
