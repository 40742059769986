import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";
import ContentFrame from "../components/ContentFrame";
import Button from "../components/Button/Button";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <ContentFrame>
        <h1 className="font-header text-2xl inline-block my-8 p-3">
          Diese Seite existiert leider nicht...
        </h1>
        <p>Schauen Sie gerne auf unseren anderen Seiten vorbei</p>
        <Button to="/">Homepage</Button>
      </ContentFrame>
    </Layout>
  );
}

export default NotFoundPage;
